<template>
<!--  <NewConsulting @hide="Showconsulting = false" v-if="Showconsulting" />-->
  <div class="consulting required-item companies">
    <div class="container">
      <h2 class="title-profile mt-0">{{ $route.name }} ({{ Count_Consulting.available_subscription_consultations }} {{
    $t('Consultation') }})</h2>
      <p class="desc">{{ $t('consultations_page_desc') }}</p>
      <div class="col-12">
        <ul class="nav nav-pills" id="pills-tab3" role="tablist">
          <li class="nav-item active" role="presentation">
            <button data-index="Packages1" class="nav-link py-3 active" id="v-pills-Packages1-tab" data-bs-toggle="pill"
              data-bs-target="#v-pills-Packages1" type="button" role="tab" aria-controls="v-pills-Packages1"
              aria-selected="true">
              <span class=" fs-sm-4">{{ $t('Consulting') }}</span>
            </button>
          </li>
          <li class="nav-item " role="presentation">
            <button data-index="Packages2" class="nav-link py-3 " id="v-pills-Packages2-tab" data-bs-toggle="pill"
              data-bs-target="#v-pills-Packages2" type="button" role="tab" aria-controls="v-pills-Packages2"
              aria-selected="true">
              <span class=" fs-sm-4">{{ $t('Balance') }}</span>
            </button>
          </li>
        </ul>
        <div class="tab-content mt-3" id="pills-tabContent">
          <div class="tab-pane fade show active" id="v-pills-Packages1" role="tabpanel"
            aria-labelledby="v-pills-Packages1-tab">
            <div class="row">
              <div class="head-section-profile">
                <div class="search">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.92 13.92L17 17M16.111 8.581C16.111 12.768 12.728 16.162 8.556 16.162C4.383 16.162 1 12.768 1 8.582C1 4.393 4.383 1 8.555 1C12.728 1 16.111 4.394 16.111 8.581Z"
                      stroke="#0349A8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <input @input="FilterSearch" v-model="Search" type="text"
                                        :placeholder="$t('Search')">
                </div>
                <div>
                  <button class="btn-main" data-bs-toggle="modal" data-bs-target="#NewConsulting">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.73684 14.7368C6.73684 15.0719 6.86992 15.3931 7.10681 15.63C7.3437 15.8669 7.66499 16 8 16C8.33501 16 8.6563 15.8669 8.89319 15.63C9.13008 15.3931 9.26316 15.0719 9.26316 14.7368V9.26316H14.7368C15.0719 9.26316 15.3931 9.13008 15.63 8.89319C15.8669 8.6563 16 8.33501 16 8C16 7.66499 15.8669 7.3437 15.63 7.10681C15.3931 6.86992 15.0719 6.73684 14.7368 6.73684H9.26316V1.26316C9.26316 0.928148 9.13008 0.606858 8.89319 0.36997C8.6563 0.133082 8.33501 0 8 0C7.66499 0 7.3437 0.133082 7.10681 0.36997C6.86992 0.606858 6.73684 0.928148 6.73684 1.26316V6.73684H1.26316C0.928148 6.73684 0.606858 6.86992 0.36997 7.10681C0.133082 7.3437 0 7.66499 0 8C0 8.33501 0.133082 8.6563 0.36997 8.89319C0.606858 9.13008 0.928148 9.26316 1.26316 9.26316H6.73684V14.7368Z"
                        fill="white" />
                    </svg>
                    {{ $t('New Consultation') }}
                  </button>
                  <NewConsulting />
                </div>
              </div>
              <div class="consulting-tabel">
                <div class="row head-tabel">
                  <div class="col">
                    {{ $t('Reference Number') }}
                  </div>
                  <div class="col" style="margin-right: 55px;">
                    {{ $t('Consultation Details') }}
                  </div>
                  <div class="col" style="margin-right: 55px;">
                    {{ $t('Status') }}
                  </div>
                  <div class="col" style="margin-right: 55px;">
                    {{ $t('How Apply') }}
                  </div>
                  <div class="col" style="margin-right: 55px;">
                    {{ $t('Consultation Date') }}
                  </div>
                </div>
                <div class="row body-tabel" v-for="item in Consultation" :key="item.id" v-if="Consultation.length > 0">
                  <div class="col">
                    {{ item.id }}
                  </div>
                  <div class="col" style="margin-right: 70px;">
                    {{ item.description }}
                  </div>
                  <div class="col" style="margin-right: 70px;">
                    {{ item.show_status }}
                  </div>
                  <div class="col" style="margin-right: 70px;">
                    {{ item.show_type }}
                  </div>
                  <div class="col" style="margin-right: 70px;">
                    {{ item.created_at }}
                  </div>
                </div>
                <NoDataToShow v-else />
              </div>
            </div>
          </div>
          <div class="tab-pane fade show" id="v-pills-Packages2" role="tabpanel"
            aria-labelledby="v-pills-Packages2-tab">
            <div class="cons-block d-flex justify-content-between mb-4 mt-4 unit-box">
              <div class="d-flex unit-box">

                <div class="first">
                  <p class="box" style="font-size:20px; font-weight: 700;">
                    {{ $t('Subscriptions Consulting Balance') }}
                  </p>

                </div>
              </div>
              <div class="d-flex unit-box">

                <div class="second">
                  <span class="span-title">
                    {{ $t('All Consulting') }}
                  </span>
                  <p class="span-value1">{{ Count_Consulting.total_subscription_consultations }} {{
    $t('Consultation') }}</p>

                </div>
                <div class="third">
                  <span class="span-title">
                    {{ $t('Consumed Consultations') }}
                  </span>
                  <p class="span-value2">{{ Count_Consulting.consumed_subscription_consultations }} {{
    $t('Consultation') }}</p>
                </div>
                <div class="fourth">
                  <span class="span-title">
                    {{ $t('Available Consultations') }}
                  </span>
                  <p class="span-value3">{{ Count_Consulting.available_subscription_consultations }} {{
    $t('Consultation') }}</p>
                </div>
              </div>
            </div>
            <div class="cons-block d-flex justify-content-between mb-4 mt-4">
              <div class="d-flex ">
                <div style="padding: 17px 10px 0 10px;">
                  <p class="box" style="font-size:20px; font-weight: 700;">
                    {{ $t('My Personal Consulting Balance') }}
                  </p>

                </div>
              </div>
              <div class="d-flex ">
                <div style="padding: 17px 10px 0px 10px;">
                  <span class="span-title"></span>
                  <p class="text-center span-value3">{{ Count_Consulting.my_availabe_consultations }} {{
    $t('Consultation') }}</p>
                </div>
              </div>
              <div></div>
            </div>
            <p class="span-title">{{ $t('You can request to charge your consultation balance') }}</p>

            <Form @submit="OnSubmit" :validation-schema="schema"
              class="mb-4 mt-4 pb-2">
              <div class="d-flex">
                <button class="btn-main" data-bs-toggle="modal" data-bs-target="#ConsultationPopup">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.73684 14.7368C6.73684 15.0719 6.86992 15.3931 7.10681 15.63C7.3437 15.8669 7.66499 16 8 16C8.33501 16 8.6563 15.8669 8.89319 15.63C9.13008 15.3931 9.26316 15.0719 9.26316 14.7368V9.26316H14.7368C15.0719 9.26316 15.3931 9.13008 15.63 8.89319C15.8669 8.6563 16 8.33501 16 8C16 7.66499 15.8669 7.3437 15.63 7.10681C15.3931 6.86992 15.0719 6.73684 14.7368 6.73684H9.26316V1.26316C9.26316 0.928148 9.13008 0.606858 8.89319 0.36997C8.6563 0.133082 8.33501 0 8 0C7.66499 0 7.3437 0.133082 7.10681 0.36997C6.86992 0.606858 6.73684 0.928148 6.73684 1.26316V6.73684H1.26316C0.928148 6.73684 0.606858 6.86992 0.36997 7.10681C0.133082 7.3437 0 7.66499 0 8C0 8.33501 0.133082 8.6563 0.36997 8.89319C0.606858 9.13008 0.928148 9.26316 1.26316 9.26316H6.73684V14.7368Z"
                          fill="white" />
                  </svg>
                  {{ $t('Submit Order') }}
                </button>
                <!-- Consultation Popup Component -->
                <ConsultationPopup />
<!--                <ConsultationPopup v-if="showPopup" @hide="showPopup = false" />-->
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <Spinner />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import Cookie from 'cookie-universal';
import { notify } from "@kyvg/vue3-notification";
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";

const cookie = Cookie();

export default {
  data() {
    return {
      Showconsulting: false,
      Consultation: [],
      Count_Consulting: {},
      paymentMethods:[],
      Search: '',
      showPopup: false
    }
  },
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = yup.object({
      Pay_id: yup
        .string()
        .required(cookie.get('lang') === 'ar' ? 'يجب اختيار طريقة دفع ' : 'must be select payment'),
      consultations_number: yup.mixed().test(
        'number',
        (field) => {
          return cookie.get('lang') == 'ar' ? 'يجب إدخال رقم ' : 'must enter a number';
        },
        (value) => typeof value === 'number',
      ).test(
        'number',
        (field) => {
          return cookie.get('lang') == 'ar' ? 'يجب الا يتجاوز العدد 200 استشارة' : 'The number should not exceed 200 consultations';
        },
        (value) => value <= 200,)
        .required(cookie.get('lang') == 'ar' ? 'عدد الاستشارات مطلوب' : 'Number of Consultation required'),
    });
    return {
      schema,
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    NewConsulting: defineAsyncComponent(() => import('@/components/Profile/modals/NewConsulting.vue')),
    NoDataToShow: defineAsyncComponent(() => import('@/components/Global/NoDataToShow.vue')),
    Spinner: defineAsyncComponent(() => import('@/components/Global/Spinner.vue')),
    ConsultationPopup: defineAsyncComponent(() => import('@/components/Profile/modals/ConsultationPopup.vue')),
  },
  methods: {
    openPopup () {
      this.showPopup = true
    },
    closePopup () {
      this.showPopup = false
    },
    handleSubmission (consultationDetails) {
      alert('Consultation Request Submitted: ' + consultationDetails);
      this.showPopup = false
    },
    OnSubmit(values) {
      const FData = new FormData();
      FData.append('consultations_number', values.consultations_number);
      axios
        .post(`/company/dashboard/consultations/buy-consultations/payment-method/${values.Pay_id}`, FData, {
          headers: {
            "Authorization": `Bearer ${cookie.get('CompanyToken')}`
          }
        })
        .then((res) => {
          if (res.status == 200) {
            notify({
              type: "success",
              text: this.$i18n.locale == 'ar' ? 'تم ارسال الطلب بنجاح' : 'The request has been sent successfully.',
            });
            window.open(res.data.data.payment_url, '_blank');
          }
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    GetConsulting() {
      axios.get(`/company/dashboard/consultations`, {
        headers: {
          "Authorization": `Bearer ${cookie.get('CompanyToken')}`
        }
      })
        .then((res) => {
          this.Consultation = res.data.data.Consultation;
          this.paymentMethods = this.Consultation[0].paymentMethods;
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    GetStatisticsConsulting() {
      axios.get(`/company/dashboard/consultations/statistics`, {
        headers: {
          "Authorization": `Bearer ${cookie.get('CompanyToken')}`
        }
      })
        .then((res) => {
          this.Count_Consulting = res.data.data;
        })
        .catch(function (error) {
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    FilterSearch() {
  var self = this;
  this.$store.dispatch('SETSpinner', true);

  // Conditionally build the URL
  let url = '/company/dashboard/consultations';
  if (this.Search) {
    url += `?search=${this.Search}`;
  }

  axios.get(url, {
    headers: {
      "Authorization": `Bearer ${cookie.get('CompanyToken')}`
    }
  })
  .then((res) => {
    this.Consultation = res.data.data.Consultation;
    this.paymentMethods = this.Consultation[0].paymentMethods;
    this.$store.dispatch('SETSpinner', false);
  })
  .catch(function (error) {
    self.$store.dispatch('SETSpinner', false);
    notify({
      type: "error",
      text: error.response.data.message,
    });
  });
},


  },
  mounted() {
    this.GetConsulting();
    this.GetStatisticsConsulting();
  }
};
</script>
